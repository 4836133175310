//
//.:..:..:..:..:..:..:..:.
// Tailor'd Design
// jesse@tailord.design
//.:..:..:..:..:..:..:..:.
//

import React, {Component} from 'react';

class SiteFooter extends Component {
	
	render() {
		return (
			<footer id="footer">
				<div id="block-block-2">
					<button><a href="mailto:contact@tailord.design">Request Service</a></button>
					<p>&copy; Tailor'd Design 2013-2019.<br />All Rights Reserved.</p>
				</div>
			</footer>
		);
	}
}


export default SiteFooter;