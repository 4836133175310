//
//.:..:..:..:..:..:..:..:.
// Tailor'd Design
// jesse@tailord.design
//.:..:..:..:..:..:..:..:.
//

import React, {Component} from 'react';
// import {DrupalContent} from '../Content/DrupalContent';
// import {cms} from '../Content/cms';
// import contentfulClient from '../Content/contentfulClient';

// const root_path = process.env.PUBLIC_URL+'/img/';

// use ID to find post in json
// load that object's fields into the template

class Project extends Component {
	// constructor(props) {
	// 	super(props);
	// 	// this.state = {
	// 	// 	projectId: this.props.projectId
	// 	// };
	// }

	render() {
		// let id = this.props.projectId;
		// let project = cms.nodes.find(function(p) {
		// 	return p.node.nid === id
		// });
		let project = this.props.project;
		// vars
		// const hero_file = project.field_hero.src.replace('styles/large/public/','');
		// const hero_file = 		'/img/'+project.field_hero.src;
		// const overlay_color = 	'rgba('+project.field_overlay_rgb+',0.3)';
		// const caption = 		project.field_hero_caption;
		// const title = 			project.title;
		// const client = 			project.field_client;
		// const link = 			project.project_link;
		// const body = 			project.body;
		// const hand_offs = 		project.field_hand_offs;
		// const images = 			project.field_project_image;

		const hero_file = 		project.hero.fields.file.url;
		const overlay_color = 	'rgba('+project.rgbOverlay+',0.3)';
		const caption = 		project.heroCaption;
		const title = 			project.title;
		const client = 			project.client;
		const link = 			project.projectLink;
		const body = 			project.body;
		const hand_offs = 		project.handOffs;
		const images = 			project.projectImage;
		let project_button;
		const handoffLen = 		hand_offs.length;
		let handoffDivider = 	', ';

		if (link){
			project_button = <button className="button--small"><a href={link} target="_blank" rel="noopener noreferrer">Visit Project</a></button>;
		}
		
		return (
			<div className="clearfix">
				<section className="hero opening" style={{backgroundImage:'url('+hero_file+')'}}>
					<div className="title">
						<h2>{caption}</h2>
					</div>
					<div className="bg-color" style={{backgroundColor:overlay_color}}>
						<img src={hero_file} id="bg-img" alt="" />
					</div>
				</section>
				<section className="project-view clearfix">
					<article className="clearfix">
						<h1 className="project-title">{title}</h1>
						<h3 className="project-client">{client}</h3>
						{project_button}
						<div className="field-name-body">
							<p>{body}</p>
						</div>
						<p className="hand-offs">
							{hand_offs.map((handoff, i) => {
								if (handoffLen === i + 1){handoffDivider = '';}
								return <span key={i}>{handoff}{handoffDivider}</span>
							})}
						</p>
					</article>
					<div className="masonry clearfix">
						{images.map((image, i) => {
							if(i%3 === 0){
								return <img key={i} src={image.fields.file.url} alt={image.fields.title} className="brick" width="1200" />
							} else {
								return <img key={i} src={image.fields.file.url} alt={image.fields.title}className="brick" width="800" />
							}
						})}
					</div>
				</section>
			</div>
		);
	}
}


export default Project;