//
//.:..:..:..:..:..:..:..:.
// Tailor'd Design
// jesse@tailord.design
//.:..:..:..:..:..:..:..:.
//

import React, {Component} from 'react';


class ProjectTeaser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			projectId: this.props.projectId
		};
	}

	render() {
		let id = this.state.projectId;
		let project = this.props.cms;
		project = project.fields;

		const hero_file = 		project.hero.fields.file.url;
		const overlay_color = 	'rgba('+project.rgbOverlay+',0.3)';
		const title = 			project.title;
		const client = 			project.client;

		return (
			<figure className="project" onClick={this.props.viewProjectCard.bind(this,id, project)}>
				<div className="teaser2" style={{backgroundColor:overlay_color}}>
					<img src={hero_file} alt={title} />
					<figcaption>
						<h3>{title}</h3>
						<h4>{client}</h4>
						<span className="icon icon--eye"></span>
					</figcaption>
				</div>
			</figure>
		);
	}
}


export default ProjectTeaser;