//
//.:..:..:..:..:..:..:..:.
// Tailor'd Design
// jesse@tailord.design
//.:..:..:..:..:..:..:..:.
//

import React, { Component } from 'react';
import contentfulClient from './Content/contentfulClient';
import $ from 'jquery';
import './Styles/_styles.scss';

import BlockSiteHero from './Blocks/siteHero';
import ViewFeaturedStories from './Views/featuredStories';
import BlockServices from './Blocks/services';
// import BlockServiceLogos from './Blocks/serviceLogos';
// import BlockClientLogos from './Blocks/clientLogos';
import Project from './Components/project';
import SiteHeader from './Layout/header';
import SiteFooter from './Layout/footer';

// import {cms} from './Content/cms';

// import logo from './logo.svg';

/*
hero
feature projects
services
service-logos
client-logos
header logo
nav
nav-contact
footer
*/

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			projectId: 0,
			showProject: false,
			lazyLoadNum: 6,
			// projects:cms.nodes.filter(p => p.node.enabled),
			cms:[]
		};
		this.viewProjectCard = this.viewProjectCard.bind(this);
		this.hideProjectCard = this.hideProjectCard.bind(this);
		this.addMore = this.addMore.bind(this);
		this.fader = this.fader.bind(this);
	}

	componentDidMount() {
		this.fetchPosts().then(this.setPosts);

		// this.setState({
		// 	total:this.state.projects.length
		// });
		//fader
		let el = $('[data-fade-this]');
		const buf = 0.68;
		this.fader(el, buf);
	}

	fetchPosts = () => contentfulClient.getEntries();
	setPosts = response => {
		//sort by date
		response.items.sort(function(a,b){
		  return new Date(b.sys.createdAt) - new Date(a.sys.createdAt);
		});
		this.setState({
			cms:response.items,
			total:response.items.length
		});
	}

	viewProjectCard(id, project) {
		this.setState({
			projectId:id,
			showProject:true,
			activeProject:project
		});
		$('html, body').animate({scrollTop:0}, 500, function() {
			$('#site-main').addClass('fade');
			$('.project-card').addClass('show');
		});
		$('#site-main').on('click', this.hideProjectCard);
	}

	hideProjectCard() {
		this.setState({showProject:false});
		$('#site-main').removeClass('fade').find('.project-card').removeClass('show');
	}

	addMore(event) {
		let count = this.state.lazyLoadNum * 2;
		this.setState({
			lazyLoadNum:count
		}, function(){
			if(this.state.lazyLoadNum >= this.state.total) {
				$('button#show-more').hide();
			}
		});
	}

	fader(el, buf) {
		el.addClass('data-fading-block');
		el.each(function() {
			// const items = el.find('> *');
			el.each(function(i) {
				const t = $(this).offset().top;
				let _lock = false;
				const $e = $(this);
				$(window).scroll(function(){
					const wH = $(this).height();
					const trigger = t - (wH * buf);
					const s = window.pageYOffset;
					if ((s > trigger) && !_lock) {
						$e.addClass('fade-in');
						_lock = true;
					} else if ((s < trigger) && _lock) {
						$e.removeClass('fade-in');
						_lock = false;
					}
				});
			});
		});
		//usage 
		//fader($('.services'), 0.68);
	}

	render() {
		let projectCard;
		if (this.state.showProject){
			projectCard = <div className="project-card"><Project projectId={this.state.projectId} project={this.state.activeProject} /></div>
		}

		return (
	    	<div>
	    		<div id="site-main" xonClick={this.hideProjectCard}>
			    	<main id="content">
				    	<BlockSiteHero />
				    	<BlockServices />
				    	{/*<BlockServiceLogos />*/}
				    	<ViewFeaturedStories count={this.state.lazyLoadNum} viewProjectCard={this.viewProjectCard} addMore={this.addMore} cms={this.state.cms} />
				    	{/*<BlockClientLogos />*/}
			    	</main>
			    	{projectCard}
		    		<SiteHeader />
		    	</div>
	    		<SiteFooter />
	    	</div>
    	);
	}
}

export default App;
