//
//.:..:..:..:..:..:..:..:.
// Tailor'd Design
// jesse@tailord.design
//.:..:..:..:..:..:..:..:.
//

import React, {Component} from 'react';

class BlockServices extends Component {
	
	render() {
		return (
			<div className="services" data-fade-this>
				<div className="service">
					<span className="icon icon--circle icon--photo"></span>
					<h3>Visual</h3>
					<p>Need something caught on camera? Putting custom imagery with your brand can make all the difference and it'll make you stand out from the rest.</p>
				</div>
				<div className="service">
					<span className="icon icon--circle icon--brush"></span>
					<h3>Design</h3>
					<p>Custom design makes your brand unique and iconic. Quality work will leave an impression on your users that keeps them coming back.</p>
				</div>
				<div className="service">
					<span className="icon icon--circle icon--code"></span>
					<h3>Code</h3>
					<p>Tailored code for your project. Take advantage of having a design and website that suits you perfectly - no more cookie-cutter, limiting templates.</p>
				</div>
			</div>
		);
	}
}


export default BlockServices;