//
//.:..:..:..:..:..:..:..:.
// Tailor'd Design
// jesse@tailord.design
//.:..:..:..:..:..:..:..:.
//

import React, {Component} from 'react';

// const root_path = process.env.PUBLIC_URL;

class SiteHeader extends Component {
	
	render() {
		return (
			<header id="site-header">
				<a href="/" title="Home" rel="home" id="logo">
					<img src="/assets/images/logo-box.svg" alt="Home" />
				</a>
				{/* @todo <div className="region-navigation">
					<div id="block-menu-block-1">
						<div className="icon--nav-anim">
							<span className="anim-line-1"></span>
							<span className="anim-line-2"></span>
						</div>
						<div className="menu-block-1">
							<ul className="menu">
							<li className="menu__item">
								<a href="/stories">Stories</a>
							</li>
							<li className="menu__item">
								<a href="/contact" title="">Contact</a>
							</li>
							</ul>
						</div>
					</div>  
				</div> */}
			</header>
		);
	}
}


export default SiteHeader;