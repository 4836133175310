//
//.:..:..:..:..:..:..:..:.
// Tailor'd Design
// jesse@tailord.design
//.:..:..:..:..:..:..:..:.
//

import React, {Component} from 'react';

class BlockSiteHero extends Component {
	
	render() {
		return (
			<div className="hero" style={{backgroundImage:"url('/img/flowervintage.jpg')"}}>
				<div className="title">
					<h1><img src="/assets/images/tailord.svg" width="300" style={{WebkitFilter:"invert()",MozFilter:"invert()",fiter:"invert()"}} alt="Tailor'd Logo" /></h1>
					<h2 style={{color:"#fff"}}>Solutions that fit.&trade;<br />Tailor'd Design is a creative agency.<br />We develop and build brand strategies that elevate your story and make you look awesome.</h2>
				</div>
			</div>
		);
	}
}


export default BlockSiteHero;