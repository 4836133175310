//
//.:..:..:..:..:..:..:..:.
// Tailor'd Design
// jesse@tailord.design
//.:..:..:..:..:..:..:..:.
//

import * as contentful from 'contentful';


var contentfulClient = contentful.createClient({
	space:'rm7qwci0xyne',
	accessToken:'4cbc081bc6717cba2c9275a7428ec85e8c4a9c8708ac7f8c5aa5de7991a22012'
});

export default contentfulClient;