//
//.:..:..:..:..:..:..:..:.
// Tailor'd Design
// jesse@tailord.design
//.:..:..:..:..:..:..:..:.
//

import React, {Component} from 'react';
import ProjectTeaser from '../Components/projectTeaser';
// import {DrupalContent} from '../Content/DrupalContent';
// import {cms} from '../Content/cms';

class ViewFeaturedStories extends Component {
	constructor(props) {
		super(props);
			this.state = {
			// featuredList: cms.nodes.filter(function(p, i) {
			// 	if(p.node.enabled == 1) {
			// 		return p.node.promote === "Yes"	
			// 	}
			// })
		}
	}

	render() {
		// let featuredList = this.state.featuredList;
		// let featuredList = cms.nodes.filter(p => p.node.enabled && p.node.promote == true);
		// let featuredList = cms.nodes.filter(p => p.node.enabled);
		let featuredList = this.props.cms;
		// put newest first
		// featuredList.sort().reverse();
		let button;
		if (featuredList.length > this.props.count) {
			button = <button id="show-more" onClick={this.props.addMore}>See More</button>;
		}

		return (
			<div id="block-views-feature-projects-block" className="work" data-fade-this>
				<h2 className="block-title">Showcase Stories</h2>
				{featuredList.slice(0,this.props.count).map((f, i) => {
					return <ProjectTeaser key={i} projectId={f.sys.id} viewProjectCard={this.props.viewProjectCard} cms={f} />
				})}
				{button}
			</div>
		);
	}
}


export default ViewFeaturedStories;